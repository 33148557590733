#instafeed {
  &:after {
    content: "";
    display: table;
    width: 100%;
    height: 0;
  }
}
.instafeed {
  &-post {
    float: left;
    display: none;
    width: 50%;
    &:nth-of-type(1),
    &:nth-of-type(2) {
      display: block;
    }
    @media #{$tablet} {
      width: 25%;
      &:nth-of-type(3),
      &:nth-of-type(4) {
        display: block;
      }
    }
    @media #{$laptop} {
      width: 20%;
      &:nth-of-type(5){
        display: block;
      }
    }
    &-img {
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      cursor: pointer;
      overflow: hidden;
      backface-visibility: hidden;
      &:before {
        content: "";
        position: relative;
        z-index: 2;
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        background: $color--primary;
        opacity: 0;
        transition: $hover-transition;
      }
      .no-touchevents.webgl &:hover:before,
      .desktop-touch.webgl &:hover:before {
        opacity: 0.3;
      }
      &:after {
        content: "";
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
      }
    }
  }
}
