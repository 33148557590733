/*!
 *  Theme Name: FourKnivesDeli
 *  Theme URI:
 *  Author:
 *  Author URI: https://wordpress.org/
 *  Description: Custom Theme
 *  License: GNU General Public License v2 or later
 *  License URI: http://www.gnu.org/licenses/gpl-2.0.html
 *  Tags: custom
 *  Text Domain: fourknivesdeli
 *  This theme, like WordPress, is licensed under the GPL.
 */
@import "_reset";
@import "_config";
@import "_base";
@import "_typography";
@import "_animations";
