.newsletter {
  padding: 50px 0;
  background: #333;
  color: #fff;
  line-height: 2em;
  p {
    padding: 0 20px 10px;
    text-align: center;
    line-height: 1.5em;
  }
	#signupForm {
		padding: 0 35px;
	}
  .mc-field-group {
    width: 100%;
    margin: 20px auto 0;
		padding: 0 20px;
    max-width: 365px;
  }
  label {
    display: inline-block;
    width: 4em;
    text-align: right;
    padding: 0 10px 0 0;
    text-transform: uppercase;
    font-size: 0.8em;
    letter-spacing: 0.15em;
  }
  input {
    width: 100%;;
    padding: 0 5px;
    font-size: 0.75em;
    letter-spacing: 0.15em;
    border: 1px solid #fff;
    transition: background $hover-transition, color $hover-transition;
		@media (min-width:370px) {
			width: 220px;
		}
    &[type="text"],
    &[type="email"] {
			height: 3em;
      &:focus {
        background: #fff;
        color: #000;
      }
    }
    &[type="submit"] {
      display: block;
      width: 150px;
      margin: 30px auto 10px;
      padding: 12px 20px;
      line-height: 1em;
      text-transform: uppercase;
      cursor: pointer;
      &:hover,
      &:focus {
        background: #fff;
        color: #000;
      }
    }
  }
	#signup-response {
		display: block;
		margin: 0 auto;
		padding: 0 20px;
		span {
			display: block;
			padding-top: 25px;
			font-size: 0.8em;
			line-height: 1.5em;
			text-align: center;
		}
	}
}
