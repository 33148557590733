footer {
  text-align: center;
  background: #fff;
  padding: 50px 20px;
  h1 {
    text-transform: uppercase;
    margin-bottom: 20px;
    @media #{$tablet} {
      margin-bottom: 20px;
    }
    img {
      width: 150px;
      height: auto;
    }
    span {
      display: none;
      visibility: hidden;
    }
  }
  .social-icons {
    margin-bottom: 40px;
  }
  span {
    display: block;
    margin-bottom: 10px;
    line-height: 1.25em;
    @media #{$tablet} {
      display: inline-block;
      margin-bottom: 0;
      line-height: 1em;
    }
    &:after {
      @media #{$tablet} {
        content: "|";
        display: inline-block;
        padding: 0 25px;
      }
    }
    &:last-of-type {
      &:after {
        @media #{$tablet} {
          content: "";
          padding: 0;
        }
      }
    }
  }
}
