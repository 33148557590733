// ==========================================================================
// Placeholder
// ==========================================================================

// Clearfix
  %clearfix {
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }