.info {
  @extend %clearfix;
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  max-width: 800px;
  height: auto;
  padding: 50px 0 0;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  @media #{$tablet} {
		margin-top: 50px;
    padding: 50px 25px 100px;
    text-align: left;
  }
  &-announcements {
    @extend %clearfix;
    position: relative;
    max-width: 450px;
    margin: 0 auto 50px;
    padding: 40px 20px;
    @media #{$tablet} {
      max-width: 800px;
      margin: 0 auto 50px;
      &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: #fff;
      opacity: 1;
    }
    }
    p {
      max-width: 600px;
      text-align: center;
      margin: 0 auto;
      font-style: italic;
      font-size: 20px;
      line-height: 1.5em;
      @media #{$tablet} {
        font-size: 24px;
      }
    }
  }
  &-content {
    display: block;
    max-width: 400px;
    height: auto;
    margin: 0 auto;
    padding: 0 50px 50px;
    color: #000;
    @media #{$tablet} {
      float: right;
      display: block;
      margin: 0;
      width: 40%;
      padding: 0 20px 0 50px;
    }
    @media #{$laptop} {
      width: 43%;
    }
    h2 {
      font-weight: 500;
      color: #000;
      text-transform: uppercase;
    }
    & > div {
      padding: 0 0 40px;
      &:last-of-type {
        padding-bottom: 0;
      }
      p,
      a,
      pre {
        color: #000;
        padding: 10px 0 0;
      }
      &.social-icons a {
        padding: 0;
      }
      a {
        display: inline-block;
				transition: 0.3s ease-out;
        &:hover {
          color: $red;
        }
      }
      pre {
        font-family: inherit;
        overflow: hidden;
      }
    }
    .store-hours {
      display: table;
      text-align: left;
      margin: 0 auto;
      @media #{$tablet} {
        margin: 0;
      }
    }
    .hours {
      display: table-row;
      & > div {
        display: table-cell;
        padding: 10px 0 0;
      }
      & > div:nth-of-type(2) {
        padding-left: 10px;
      }
    }
  }
  &-map {
    display: block;
    height: auto;
    margin: 0 auto;
    @media #{$tablet} {
      float: left;
      margin: 0;
      width: 60%;
    }
    @media #{$laptop} {
      width: 57%;
    }
  }
  #map {
    display: block;
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    height: auto;
    @media #{$tablet} {
      margin: 0 0 0 auto;
    }
    &:before {
      content: "";
      position: relative;
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      background: #ccc;
    }
    & > div {
      top: 0;
    }
  }
}
