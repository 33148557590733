// ==========================================================================
// Base Styles
// ==========================================================================
html,
body {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  color: $color--primary;
}
html.menu-open {
  overflow: hidden;
}
body {
  position: relative;
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}
button {
  border: 0;
  outline: 0;
  background: inherit;
  cursor: pointer;
  -webkit-appearance: none;
  border-radius: 0;
}
input {
  border: 0;
  outline: 0;
  background: inherit;
  -webkit-appearance: none;
  border-radius: 0;
}
h1,
h2,
h3,
h4,
h5,
p,
nav,
header,
footer,
.title {
  display: block;
  margin: 0 auto;
  padding: 0;
  line-height: 1em;
}
h1,
h2,
h3,
h4,
h5,
h6{
  color: $color--primary;
  margin: 0;
}
img {
  width: auto;
  max-width: 100%;
  height: auto;
  outline: none;
}

header ul,
footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
  color: white;
}

header ul a,
footer ul a {
  color: white;
  text-decoration: none;
}
main {
  position: relative;
  background-color: #eee;
  @media #{$tablet} {
    background-color: transparent;
  }
}
section {
  position: relative;
  z-index: 1;
  @extend %clearfix;
}
footer {
  position: relative;
  width: 100%;
  &.sticky {
    position: fixed;
    bottom: 0;
  }
}
div.hidden,
span.hidden {
  display: none;
  visibility: hidden;
}

.bg-ctn {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  height: 100vh;
  min-height: 400px;
  overflow: hidden;
  transition: opacity 0.6s ease-out;
  .no-touchevents & {
    position: absolute;
    top: auto;
    bottom: 0;
    @media #{$tablet} {
      position: fixed;
    }
  }
  &:after {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0.25;
    background: -moz-linear-gradient(top,  rgba(117,117,117,0.2) 0%, rgba(255,255,255,0.77) 50%, rgba(255,255,255,1) 70%);
    background: -webkit-linear-gradient(top,  rgba(117,117,117,0.2) 0%,rgba(255,255,255,0.77) 50%,rgba(255,255,255,1) 70%);
    background: linear-gradient(to bottom,  rgba(117,117,117,0.2) 0%,rgba(255,255,255,0.77) 50%,rgba(255,255,255,1) 70%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33757575', endColorstr='#ffffff',GradientType=0 );
  }
}
.bg {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateZ(0) scale(1.015);
  background-size: cover;
  background-position: bottom center;
}

.social-icons {
  a {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 25px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  svg {
    display: inline-block;
    width: 25px;
    height: 25px;
    stroke-width: 0;
    stroke-width: 0;
    stroke: transparent;
    fill: black;
    transition: $hover-transition;
  }
  a:hover svg{
    fill: $red;
  }
}

main {
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 0;
}
