// ==========================================================================
// Typography
// ==========================================================================
html,
body {
  font-family: $font-family--primary;
  font-weight: $font-weight--light;
  font-size: $base__font-size;
  line-height: 1em;
}

h1,
h2,
h3,
h4,
h5,
h6{
  font-family: $font-family--secondary;
  line-height: 1em;
}