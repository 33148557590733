// ==========================================================================
// Global Config
// ==========================================================================

// Text
  $base__font-size : 16px;

// Font Weights
  $font-weight--thin       : 100;
  $font-weight--light      : 300;
  $font-weight--normal     : 400;
  $font-weight--semibold   : 600;
  $font-weight--bold       : 700;
  $font-weight--ultra-bold : 900;

// Font Stacks
  $font-family--primary       : 'Open Sans', sans-serif;
  $font-family--secondary     : 'Maven Pro', sans-serif;

// Descriptive Base Colors
  $white      : #ffffff;
  $black      : #000000;
  $red        : #fe0000;

  $color--primary       : $black;
  $color--secondary     : $red;


// Responsive Sizes
  $mobile-only : "(max-width: 767px)";
  $tablet      : "(min-width: 768px)";
  $laptop      : "(min-width: 1025px)";
  $desktop     : "(min-width: 1200px)";
  $mac         : "(min-width: 1400px)";
// @media #{$mobile-only} {
//   // code here
// }

// Transitions
  $base-transition: 0.45s ease-in-out;
  $hover-transition: 0.25s ease-out;
