.contact {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding: 0 0 1px;
  height: auto;
  @media #{$tablet} {
    max-width: 670px;
    margin: 0 auto 100px;
  }
  @media #{$laptop} {
    max-width: 800px;

  }
  &-content {
    position: relative;
    z-index: 1;
    color: #fff;
    margin: 0 auto;
    padding: 30px 30px 40px;
    line-height: 2em;
    @media #{$tablet} {
      padding: 100px 150px;
    }
    @media #{$laptop} {
      padding: 150px 200px;
    }
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: top center;
      opacity: 0.8;
    }
    &:after {
      content: "";
      position: absolute;
      z-index: -2;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.9;
    }
  }
  &-title {
    margin-bottom: 30px;
    font-size: 2em;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    text-align: center;
    color: inherit;
  }
  .contact-field-ctn {
    margin-bottom: 20px;
  }
  label {
    display: inline-block;
    width: 6.1em;
    text-align: left;
    padding: 0;
    text-transform: uppercase;
    font-size: 0.8em;
    letter-spacing: 0.15em;
  }
  input,
  textarea {
    display: block;
    width: 100%;
    padding: 0 5px;
    font-size: 0.75em;
    letter-spacing: 0.15em;
    border: 1px solid #fff;
    transition: background $hover-transition, color $hover-transition;
  }
  input {
    &[type="text"],
    &[type="email"] {
			height: 3em;
      background: rgba(255,255,255,0.6);
      color: #000;
      &:focus {
        background: rgba(255,255,255,1);
      }
    }
    &[type="submit"] {
      display: block;
      width: 150px;
      margin: 30px auto 10px;
      padding: 12px 20px;
      line-height: 1em;
      text-transform: uppercase;
      cursor: pointer;
      border: 2px solid #fff;
      &:hover,
      &:focus {
        background: #fff;
        color: #000;
      }
    }
  }
	.invalid > input[type="text"],
	.invalid > input[type="email"] {
		border: 1px solid $red;
	}
  textarea {
    outline: none;
    background: rgba(255,255,255,0.6);
    color: #000;
    &:focus {
      background: rgba(255,255,255,1);
    }
  }
	#form_response {
		max-width: 400px;
		margin: 0 auto;
		span {
			display: block;
			font-size: 0.8em;
			line-height: 1.5em;
			text-align: center;
		}
	}
  &-recaptcha {
    text-align: center;
  }
  .g-recaptcha{
    display: inline-block;
    opacity: 0.8;
  }
  .wpcf7 [role="alert"] {
    // display: none;
  }
}
