.about {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: auto;
  padding: 2px 0 0;
  @media #{$tablet} {
    padding: 50px;
  }
  &-content {
    @extend %clearfix;
    position: relative;
    max-width: 750px;
    margin: 0 auto;
    @media #{$tablet} {
      margin: 0 auto 50px;
    }
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: #fff;
      opacity: 1;
    }
  }
  &-title {
    position: relative;
    z-index: 3;
    padding: 70px 30px;
    font-size: 2.5em;
    font-weight: 500;
    line-height: 1.2em;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    color: #fff;
    @media #{$tablet} {
      padding: 75px;
    }
    &:before {
      content: "";
      position: absolute;
      z-index: -2;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: #000;
      background-position: bottom right;
      background-size: cover;
      opacity: 0.9;
      @media #{$tablet} {
        opacity: 0.9;
      }
    }
    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.4;
      @media #{$tablet} {
        opacity: 0.2;
      }
    }
    &-text {
      display: block;
      max-width: 300px;
      opacity: 0;
      transition: opacity 0.6s ease-out;
      &.trigger-active {
        opacity: 1;
      }
    }
  }
  &-copy {
    padding: 30px 30px 40px;
    color: #000;
    @media #{$tablet} {
      padding: 50px 75px 75px;
    }
    img {
      display: block;
      margin: 0 auto;
    }
    p {
      padding-bottom: 1em;
      line-height: 1.5em;
      @media #{$tablet} {
        line-height: 1.75em;
      }
      &:last-of-type {
        padding-bottom: 0;
      }
      img {
        width: auto;
        height: auto;
        max-width: 100%;
      }
    }
  }
  &-services {
    @extend %clearfix;
    max-width: 850px;
    margin: 0 auto;
    padding: 0;
    @media (min-width:1000px) {
      max-width: 1150px;
      padding: 0 50px;
    }
    & > div {
      position: relative;
      height: auto;
      margin-bottom: 1px;
      overflow: hidden;
      @media #{$tablet} {
        height: 0;
        padding-bottom: 200px;
        margin-bottom: 20px;
      }
      @media (min-width: 900px) {
        float: left;
        width: 30%;
        margin-bottom: 0;
        margin-right: 5%;
        padding-bottom: 250px;
      }
      &:last-of-type {
        margin-bottom: 0;
        @media #{$tablet} {
          margin-right: 0;
        }
      }
      &:before {
        content: "";
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        opacity: 0.8;
      }
      &:after {
        content: "";
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 0.8;
      }
      .about-icon {
        display: block;
        width: 100px;
        height: auto;
        margin: 0 auto;
        // svg {
        //   width: 100%;
        //   height: auto;
        //   fill: #000;
        // }
        img {
          width: 100%;
          height: auto;
        }
      }
      h3 {
        z-index: 3;
        position: relative;
        display: block;
        padding: 30px 20px;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.2em;
        text-transform: uppercase;
        text-align: center;
        color: #000;
        width: 100%;
        text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
        @media #{$tablet} {
          position: absolute;
          top: 50%;
          transform: translate(0,-50%);
          padding: 0 20px;
        }
      }
    }
  }
}
