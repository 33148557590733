.catering {
  display: block;
  margin: 0 auto;
  padding: 50px 25px 0;
  background: rgba(255,255,255,1);
  @media #{$tablet} {
    max-width: 1200px;
    padding: 50px;
    margin: 0 50px 100px;
  }
  @media (min-width:1000px) {
    margin: 0 100px 100px;
  }
  @media (min-width:1400px) {
    margin: 0 auto 100px;
  }
  h2 {
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 0.2em;
    text-align: center;
    text-transform: uppercase;
    padding: 50px 0 75px;
  }
  &-images {
    padding-top: 50px;
  }
  img {
    display: block;
    max-width: 100%;
    margin: 0 auto 25px;
    @media #{$tablet} {
      margin: 0 5% 25px;
      width: 40%;
      max-width: 600px;
      float: left;
    }
    @media #{$laptop} {
      margin: 0 2.5% 25px;
      width: 20%;
      float: left;
    }
  }
}

.catering {
  &-category {
    &,
    &-sizer {
      display: block;
      width: 100%;
      margin: 0 auto;
      padding-bottom: 50px;
      @media (min-width:800px) {
        &.wide {
          width: 100%;
        }
        float: left;
        width: 50%;
        padding: 25px 50px 50px;
      }
    }
    &:nth-of-type(2n+1) {
      @media (min-width:800px) {
        clear: left
      }
    }
    &-sizer {
      position: absolute;
    }
    &-title {
      font-size: 24px;
      font-weight: 500;
      text-transform: uppercase;
      text-align: left;
      padding-bottom: 0;
    }
    &-description {
      font-size: 14px;
      padding-top: 5px;
    }
    &-notes {
      padding-top: 10px;
      font-size: 0.8em;
    }
  }
  &-item {
    &-ctn {
      width: 100%;
      margin-top: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 16px;
      &:first-of-type {
        border-top: 1px solid #333;
      }
    }
    & {
      display: table;
      width: 100%;
      font-weight: 800;
    }
    &-text {
      display: table-cell;
      width: 100%;
      line-height: 1.3em;
    }
    &-description {
      padding-top: 5px;
      font-size: 0.8em;
      font-weight: 400;
      font-style: italic;
    }
  }
}
