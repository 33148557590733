.menu {
  display: block;
  margin: 0 auto 20px;
  padding: 50px 25px 0;
  background: rgba(255,255,255,1);
  @media #{$tablet} {
    max-width: 1200px;
    padding: 50px;
    margin: 0 50px 100px;
  }
  @media (min-width:1000px) {
    margin: 0 100px 100px;
  }
  @media (min-width:1400px) {
    margin: 0 auto 100px;
  }
  h2 {
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 0.2em;
    text-align: center;
    text-transform: uppercase;
    padding: 50px 0 75px;
  }
  &-images {
    padding-top: 50px;
  }
  img {
    display: block;
    max-width: 100%;
    margin: 0 auto 25px;
    @media #{$tablet} {
      margin: 0 5% 25px;
      width: 40%;
      max-width: 600px;
      float: left;
    }
  }
}

.menu {
  &-category {
    &,
    &-sizer {
      display: block;
      width: 100%;
      margin: 0 auto;
      padding-bottom: 50px;
      @media (min-width:800px) {
        float: left;
        width: 50%;
        padding: 25px 50px 50px;
      }
    }
    &:nth-of-type(2n+1) {
      @media (min-width:800px) {
        clear: left
      }
    }
    &-sizer {
      position: absolute;
    }
    &-title {
      font-size: 24px;
      font-weight: 500;
      text-align: left;
      text-transform: uppercase;
      padding-bottom: 0;
    }
    &-description {
      font-size: 14px;
      padding-top: 5px;
    }
    &-notes {
      padding-top: 25px;
      font-size: 0.8em;
    }
  }
  &-item {
    &-ctn {
      width: 100%;
      margin-top: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 16px;
      &:first-of-type {
        border-top: 1px solid #333;
      }
    }
    & {
      display: table;
      width: 100%;
      font-weight: 800;
    }
    &-text {
      display: table-cell;
      width: 80%;
      line-height: 1.3em;
    }
    &-description {
      padding-top: 5px;
      font-size: 0.8em;
      font-weight: 400;
      font-style: italic;
    }
    &-cost {
      &:before {content:"$";}
      display: table-cell;
      width: 20%;
      text-align: right;
    }
  }
  .dual-price & {
    &-item {
      &-text {
        display: table-cell;
        width: 60%;
        line-height: 1.3em;
      }
      &-cost-1,
      &-cost-2 {
        &:before {content:"$";}
        display: table-cell;
        width: 20%;
        text-align: right;
        font-weight: 800;
      }
    }
    &-cost-label {
      &-ctn {
        width: 100%;
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid #333;
      }
      & {
        @extend %clearfix;
        font-weight: 800;
        text-align: right;
      }
      &-1,
      &-2 {
        float: right;
        width: 20%;
      }
    }
  }
  &-additions {
    & {
      padding-top: 15px;
    }
    &-item {
      @extend %clearfix;
      font-size: 0.8em;
      &-text {
        display: block;
        float: right;
        line-height: 1.3em;
      }
      &-cost {
        &:before {content:"$";}
        display: block;
        float: right;
        width: 60px;
        text-align: right;
        font-weight: 800;
      }
    }
  }
}
.submenu {
  &-item {
    & {
      display: table;
      width: 100%;
      font-weight: 800;
    }
    &-text {
      display: table-cell;
      width: 80%;
      line-height: 1.3em;
    }
    &-cost {
      &:before {content:"$";}
      display: table-cell;
      width: 20%;
      text-align: right;
    }
  }
}
