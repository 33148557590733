.hero {
  position: relative;
  z-index: 0;
  display: table;
  width: 100%;
  height: 100%;
  height: 100vh;
  min-height: 400px;
  &-content-ctn {
    position: relative;
    z-index: 0;
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    padding: 0 20px;
    text-align: center;
  }
  &-content {
    position: relative;
    display: block;
    width: 100%;
    max-width: 480px;
    height: auto;
    margin: 0 auto;
    p {
      padding-top: 10px;
      line-height: 1.75em;
    }
  }
  .scroll {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    padding-bottom: 20px;
    transition: opacity 0.45s ease-out;
    opacity: 0;
    &:before {
      content: "scroll";
      font-size: 0.8em;
      font-weight: 800;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #000;
      @media #{$tablet}{
        color: #000;
      }
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      display: block;
      width: 15px;
      height: 15px;
      border: 2px solid #000;
      border-top: 1px solid transparent;
      border-left: 1px solid transparent;
      @media #{$tablet}{
        border: 2px solid #000;
        border-top: 1px solid transparent;
        border-left: 1px solid transparent;
      }
    }
    .loaded & {
      opacity: 1;
    }
    .loaded &.trigger-active {
      opacity: 0;
    }
  }
  .logo {
    display: block;
    width: 250px;
    height: auto;
    margin: 0 auto 10px;
  }
  &-links {
    padding-top: 25px;
    a {
      margin-right: 40px;
      &:last-of-type {
        margin-right: 0;
      }
      &:hover svg {
        fill: $red;
      }
    }
  }
  svg {
    width: 25px;
    height: 25px;
    stroke-width: 0;
    fill: $black;
    transition: 0.3s ease-out;
  }
}
