.header {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 0;
  visibility: hidden;
  opacity: 0;
  background-color: transparent;
  transition: $base-transition;
  @media #{$tablet} {
    padding: 0 20px;
  }
  &.trigger-active {
    background-color: #111;
  }
  .loaded & {
    visibility: visible;
    opacity: 1;
  }
}
.site-name {
  position: relative;
  z-index: 2;
  height: auto;
  line-height: 50px;
  padding: 0 20px;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #fff;
  background-color: #111;
  opacity: 0;
  transition: opacity 0.45s ease-out, color $base-transition;
	@media (min-width: 360px) {
		font-size: 18px;
	}
  @media #{$tablet} {
    float: left;
    padding: 0;
    background-color: transparent;
    color: #000;
    transition: transform 0s;
  }
  a {
    transition: color $hover-transition;
    &:hover {
      color: $red;
    }
  }
  .loaded & {
    opacity: 1;
  }
  .loaded .trigger-active & {
    color: #fff;
  }
}
.nav {
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100%;
  line-height: 50px;
  font-size: 14px;
  font-weight: 500;
  vertical-align: middle;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  background-color: #111;
  transform: translateY(-100%);
  color: #fff;
  transition: opacity 0.45s ease-out, transform 0.6s ease-out, color $base-transition;
  @extend %clearfix;
  @media #{$tablet} {
    position: relative;
    float: right;
    width: auto;
    color: #000;
    background-color: transparent;
    transform: translateY(0);
    transition: transform 0s;
  }
  .menu-open & {
    transform: translateY(0);
  }
  .loaded & {
    opacity: 1;
  }
  .loaded .trigger-active & {
    color: #fff;
  }
  &-links {
    display: block;
    padding-left: 20px;
    transition: color $hover-transition;
    &:hover {
      color: $red;
    }
    @media #{$tablet} {
      display: inline-block;
      margin-right: 10px;
      padding-left: 0;
    }
  }
  &-icon-links {
    display: none;

  }
}
.nav-trigger {
  position: absolute;
  z-index: 3;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 35px;
  height: 30px;
  overflow: hidden;
  cursor: pointer;
  @media #{$tablet} {
    display: none;
    visibility: hidden;
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 5px;
    width: 25px;
    height: 2px;
    background: #fff;
    opacity: 0;
    transition: $base-transition;
  }
  &:before {
    transform: rotate(-45deg) scale(0);
    .menu-open & {
      opacity: 1;
      transform: rotate(-45deg) scale(1);
    }
  }
  &:after {
    transform: rotate(45deg) scale(0);
    .menu-open & {
      opacity: 1;
      transform: rotate(45deg) scale(1);
    }
  }
  span {
    position: absolute;
    top: 50%;
    left: 5px;
    width: 25px;
    height: 2px;
    background: #fff;
    opacity: 1;
    transition: $base-transition;
    &:nth-of-type(1) {
      transform: translateY(-8px) translateZ(0) scale(1);
    }
    &:nth-of-type(2) {
      transform: translateX(0) translateZ(0) scale(1);
    }
    &:nth-of-type(3) {
      transform: translateY(8px) translateZ(0) scale(1);
    }
  }
}
.menu-open .nav-trigger span {
  opacity: 0;
  &:nth-of-type(1) {
    transform: translateY(0) translateZ(0) scale(0);
  }
  &:nth-of-type(2) {
    transform: translateX(0) translateZ(0) scale(0);
  }
  &:nth-of-type(3) {
    transform: translateY(0) translateZ(0) scale(0);
  }
}
.nav-close {
  visibility: hidden;
  position: fixed;
  z-index: 9990;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100vh;
  opacity: 0;
  background: #000;
  transition: $base-transition;
  .menu-open & {
    visibility: visible;
    opacity: 0.5;
  }
  @media #{$tablet} {
    display: none;
    visibility: hidden;
  }
}
